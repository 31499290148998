<template>
	<div class="news-detail">
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg" />
		<div class="news-content jucenter">
			<div class="content-left">
				<img :src="proxy.$axios.imgUrl + info.images" />
				<div class="title">
					{{info.title}}
				</div>
				<div class="timer">
					{{info.date_en}}
				</div>
				<div class="content">
					<span v-html="info.content"></span>
				</div>
			</div>
			<div class="content-right">
				<div class="Company-title jucenter">
					News recommendations
					<div class="Company-title-border flex-center">
						<div class="red"></div>
						<div class="blue"></div>
					</div>
				</div>
				<div class="content-right-list">
					<div class="list-item alcenter" v-for="(item,index) in list" :key="index" @click="jump(item.id)">
						<img :src="proxy.$axios.imgUrl + item.images" class="left" />
						<div class="right">
							<div class="title">{{item.title}}</div>
							<div class="subhead">{{item.synopsis}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'

	const route = useRoute()
	const router = useRouter()

	import EventBus from "../../assets/common/event-bus.js"

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", 4);
		getInfo()
		getList()
		getTopimg()
	})

	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('/api/index/getNewsDetails', {
			id: route.query.id
		})
		info.value = res.data
	}


	const list = ref([])

	async function getList() {
		let res = await proxy.$axios.get('/api/index/getNewsData', {
			page: 1
		})
		if (Array.isArray(res.data) && res.data.length) {
			list.value = res.data
		}
	}

	function jump(id) {
		router.push({
			path: '/newsdetail',
			query: {
				id
			}
		})
	}
	
	
	const topImg = ref('')
	
	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'news'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg {
		margin-top: 0.05rem;
		width: 100%;
		height: auto;
	}

	.Company-title {
		font-weight: 500;
		font-size: 0.11rem;
		color: #000000;
		padding-bottom: 0.09rem;
		position: relative;
	}

	.Company-title-border {
		height: 0.02rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0.26rem;
	}

	.Company-title-border .red {
		height: 100%;
		width: 0.05rem;
		background-color: #E83328;
	}

	.Company-title-border .blue {
		height: 100%;
		width: 0.19rem;
		background-color: #008AD2;
		margin-left: 0.02rem;
	}

	.news-content {
		margin-top: 0.22rem;
		padding-bottom: 0.21rem;
	}

	.news-content .content-left {
		width: 3.05rem;
	}

	.news-content .content-left>img {
		width: 3.05rem;
		height: 2.03rem;
	}

	.news-content .content-left .title {
		width: 100%;
		font-weight: 500;
		font-size: 0.09rem;
		color: #000000;
		line-height: 0.13rem;
	}

	.news-content .content-left .timer {
		font-weight: 400;
		font-size: 0.06rem;
		color: #999999;
		line-height: 0.06rem;
		margin: 0.09rem 0 0.2rem;
	}

	.news-content .content-left .content {
		font-weight: 400;
		font-size: 0.07rem;
		color: #333333;
		line-height: 0.13rem;
	}

	.content-right {
		width: 1.4rem;
		margin-left: 0.23rem;
	}

	.content-right-list {
		margin-top: 0.04rem;
	}

	.content-right-list .list-item {
		margin-top: 0.11rem;
	}
	
	.content-right-list .list-item:hover{
		cursor: pointer;
		color: #008AD2;
	}
	
	.content-right-list .list-item:hover .subhead{
		color: #008AD2;
	}

	.content-right-list .list-item .left {
		width: 0.52rem;
		height: 0.34rem;
		margin-right: 0.08rem;
	}

	.content-right-list .list-item .title {
		font-weight: 500;
		font-size: 0.07rem;
		line-height: 0.1rem;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		white-space: nowrap;
		width: 0.79rem;
	}

	.content-right-list .list-item .subhead {
		font-weight: 400;
		font-size: 0.05rem;
		color: #666666;
		line-height: 0.09rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-top: 0.06rem;
	}
</style>